exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bravotalent-js": () => import("./../../../src/pages/bravotalent.js" /* webpackChunkName: "component---src-pages-bravotalent-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-growth-js": () => import("./../../../src/pages/growth.js" /* webpackChunkName: "component---src-pages-growth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-search-js": () => import("./../../../src/pages/support/search.js" /* webpackChunkName: "component---src-pages-support-search-js" */),
  "component---src-pages-support-talent-js": () => import("./../../../src/pages/support/talent.js" /* webpackChunkName: "component---src-pages-support-talent-js" */),
  "component---src-pages-surveys-customer-experience-js": () => import("./../../../src/pages/surveys/customer-experience.js" /* webpackChunkName: "component---src-pages-surveys-customer-experience-js" */),
  "component---src-pages-surveys-education-js": () => import("./../../../src/pages/surveys/education.js" /* webpackChunkName: "component---src-pages-surveys-education-js" */),
  "component---src-pages-surveys-human-resources-js": () => import("./../../../src/pages/surveys/human-resources.js" /* webpackChunkName: "component---src-pages-surveys-human-resources-js" */),
  "component---src-pages-surveys-index-js": () => import("./../../../src/pages/surveys/index.js" /* webpackChunkName: "component---src-pages-surveys-index-js" */),
  "component---src-pages-surveys-market-research-js": () => import("./../../../src/pages/surveys/market-research.js" /* webpackChunkName: "component---src-pages-surveys-market-research-js" */),
  "component---src-pages-talent-engage-js": () => import("./../../../src/pages/talent/engage.js" /* webpackChunkName: "component---src-pages-talent-engage-js" */),
  "component---src-pages-talent-hire-js": () => import("./../../../src/pages/talent/hire.js" /* webpackChunkName: "component---src-pages-talent-hire-js" */),
  "component---src-pages-talent-index-js": () => import("./../../../src/pages/talent/index.js" /* webpackChunkName: "component---src-pages-talent-index-js" */),
  "component---src-pages-talent-jobs-js": () => import("./../../../src/pages/talent/jobs.js" /* webpackChunkName: "component---src-pages-talent-jobs-js" */),
  "component---src-pages-tao-tai-khoan-tuyen-dung-trong-he-thong-bravotalent-js": () => import("./../../../src/pages/tao-tai-khoan-tuyen-dung-trong-he-thong-bravotalent.js" /* webpackChunkName: "component---src-pages-tao-tai-khoan-tuyen-dung-trong-he-thong-bravotalent-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-vi-vn-privacy-js": () => import("./../../../src/pages/vi-VN/privacy.js" /* webpackChunkName: "component---src-pages-vi-vn-privacy-js" */),
  "component---src-templates-blog-category-index-jsx": () => import("./../../../src/templates/blog-category/index.jsx" /* webpackChunkName: "component---src-templates-blog-category-index-jsx" */),
  "component---src-templates-blog-detail-index-jsx": () => import("./../../../src/templates/blog-detail/index.jsx" /* webpackChunkName: "component---src-templates-blog-detail-index-jsx" */),
  "component---src-templates-news-detail-index-jsx": () => import("./../../../src/templates/news-detail/index.jsx" /* webpackChunkName: "component---src-templates-news-detail-index-jsx" */),
  "component---src-templates-talents-details-index-jsx": () => import("./../../../src/templates/talents-details/index.jsx" /* webpackChunkName: "component---src-templates-talents-details-index-jsx" */)
}

